import { ISlider, sliderBottom } from '../components/slider';
import { BaseType } from 'd3-selection';
import * as d3 from '../../d3-bundle';
import { Subscription, Subject, Observable } from 'rxjs';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { SharedState } from '../shared-state';
import { ISize } from '../size';
import { IMargin } from '../margin';
import { Timer } from '../../timer';
import { PlotClippingRenderer } from '../multi-plot-viewer-base/plot-clipping-renderer';
import { throttleTime } from 'rxjs/operators';
import { ExplorationChannelMetadata } from '../channel-data-loaders/exploration-channel-metadata';

/**
 * The chart setting required by this component.
 */
interface IChartSettings {
  readonly uniqueId: string;
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly spaceBetweenPlots: number;
}

/**
 * The slider which appears under the dimension line charts in a parallel coordinates plot.
 */
export class DimensionCoordinatesSlider {

  /**
   * The slider component.
   */
  private slider?: ISlider;

  /**
   * The subscriptions to dimension news.
   */
  private dimensionSubscriptions?: Subscription;

  /**
   * The chart layout.
   */
  private layout?: IPopulatedMultiPlotLayout;

  /**
   * The chart settings.
   */
  private settings?: IChartSettings;

  /**
   * The event which is triggered when the sources change.
   */
  private sourcesChangedEvent: Subject<void> = new Subject<void>();

  /**
   * Whether the sources are changing.
   */
  private areSourcesChanging: boolean = false;

  /**
   * Create a new instance of the dimension coordinates slider.
   * @param sharedState The shared state.
   * @param dimensionIndex The index of the dimension.
   * @param xDomainNames The names of the x domains.
   * @param svg The SVG element to render the slider to.
   */
  constructor(
    private readonly sharedState: SharedState,
    private readonly dimensionIndex: number,
    private readonly xDomainNames: ReadonlyArray<string>,
    private readonly svg: d3.Selection<SVGElement, any, BaseType, any>) {
  }

  /**
   * The event which is triggered when the sources change.
   */
  public get sourcesChanged(): Observable<void> {
    return this.sourcesChangedEvent;
  }

  /**
   * Dispose of the resources used by the dimension coordinates slider.
   */
  public dispose() {
    if (this.dimensionSubscriptions) {
      this.dimensionSubscriptions.unsubscribe();
    }
  }

  /**
   * Build the dimension coordinates slider.
   */
  public build() {
    const dimensions = this.sharedState.definedDimensions;

    // Create a new slider and subscribe its move event.
    this.slider = sliderBottom()
      .on('moved', (event: any) => this.onSliderMoved(event));

    // Subscribe to dimension news.
    this.dimensionSubscriptions = dimensions.rCoordinatesNews.subscribe(
      () => this.onrCoordinatesNews());
    this.dimensionSubscriptions.add(dimensions.rCoordinatesNews.pipe(throttleTime(200)).subscribe(
      () => this.onrCoordinatesNewsThrottled()));
  }

  /**
   * Called when the r-coordinates (that is, the values between zero and one for each input
   * dimension which the red dimension line runs though) change.
   */
  private onrCoordinatesNews() {
    // Update this slider position if required.
    this.render();
  }

  /**
   * Called when the r-coordinates (that is, the values between zero and one for each input
   * dimension which the red dimension line runs though) change, but the event is throttled.
   */
  private async onrCoordinatesNewsThrottled() {
    if (this.areSourcesChanging) {
      // If we're already handling a previous event, return.
      return;
    }
    this.areSourcesChanging = true;
    try {
      await Timer.yield();
      this.sourcesChangedEvent.next();
    } finally {
      this.areSourcesChanging = false;
    }
  }

  /**
   * Set the data for the dimension coordinates slider.
   * @param layout The chart layout.
   * @param settings The chart settings.
   */
  public setData(layout: IPopulatedMultiPlotLayout, settings: IChartSettings) {
    this.layout = layout;
    this.settings = settings;
  }

  /**
   * Render the dimension coordinates slider.
   */
  public render() {
    if (!this.settings || !this.slider) {
      return;
    }
    const settings = this.settings;

    // Update the slider track length and position.
    let validPlot = this.updateSlider();

    // Create an outer container.
    let containerUpdate = this.svg.selectAll<SVGGElement, any>('.dimension-sliders').data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', 'dimension-sliders');
    let container = containerEnter.merge(containerUpdate);

    // Create a data array containing the plot we're interested in.
    let data: ProcessedPlot[] = validPlot ? [validPlot] : [];

    // Create a group for the slider, clipped to the area under the X axis of the plot.
    let gUpdate = container.selectAll<SVGGElement, ProcessedPlot>('.slider-group').data(data);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g')
      .attr('class', 'slider-group')
      .attr('clip-path', (d: ProcessedPlot) => `url(#${PlotClippingRenderer.getPlotXAxisClipPathId(settings.uniqueId, d.plotIndex)})`);
    let g = gEnter.merge(gUpdate);

    // Create a container for the slider within the group, translated to the bottom left corner of the
    // chart render area.
    let sliderContainerUpdate = g.select<SVGGElement>('.slider-container');
    let sliderContainerEnter = gEnter.append<SVGGElement>('g')
      .attr('class', 'slider-container');
    let sliderContainer = sliderContainerEnter.merge(sliderContainerUpdate);

    sliderContainer.attr('transform', (plot: ProcessedPlot) => {
      let scale = plot.column.processed.scale;
      let sourcesVisibility = this.sharedState.sourceLoaderSet.sources.map(v => v.isVisible);
      let visibleMinimum = plot.column.processed.getVisibleMinimum(sourcesVisibility);

      return 'translate('
        + (plot.absoluteRenderArea.x + scale(visibleMinimum))
        + ','
        + (plot.absoluteRenderArea.y + plot.absoluteRenderArea.height)
        + ')';
    });

    // Render the slider inside the slider container.
    sliderContainer.call(this.slider);
  }

  /**
   * Update the slider track length and position.
   */
  private updateSlider(): ProcessedPlot | undefined {
    if (!this.slider) {
      return undefined;
    }

    // Get the plot which contains the X domain we're interested in.
    let plot = this.getXDomainPlot();
    if (!plot) {
      return undefined;
    }

    // Get the visible column channel from the plot.
    let visibleChannel = this.getVisibleColumnChannel(plot);
    if (!visibleChannel) {
      return undefined;
    }

    // Get the scale and our r-coordinate (the normalized position on the scale)
    let scale = plot.column.processed.scale;
    const dimensions = this.sharedState.definedDimensions;
    let rCoordinate = dimensions.rCoordinates[this.dimensionIndex];

    let loaderMetadata = ExplorationChannelMetadata.fromProcessedMultiPlotChannel(visibleChannel);
    if (loaderMetadata) {
      // If we have metadata, map the r-coordinate from the normalized index to the normalized data value.
      // This is required as the data is not always linearly spaced.
      rCoordinate = loaderMetadata.explorationSubSweep.mapFromIndexToDataNormalizedValue(rCoordinate);
    }

    // Calculate the extents and position of the slider.
    let sourcesVisibility = this.sharedState.sourceLoaderSet.sources.map(v => v.isVisible);
    let visibleMinimum = plot.column.processed.getVisibleMinimum(sourcesVisibility);
    let visibleMaximum = plot.column.processed.getVisibleMaximum(sourcesVisibility);

    let positionMinimum = scale(visibleMinimum);
    let positionMaximum = scale(visibleMaximum);

    let trackLength = positionMaximum - positionMinimum;
    let currentValue = rCoordinate * (visibleMaximum - visibleMinimum) + visibleMinimum;
    let currentPosition = scale(currentValue) - positionMinimum;

    // Update the slider with the new values.
    this.slider
      .trackLength(trackLength)
      .position(currentPosition);

    return plot;
  }

  /**
   * Called when the slider is moved.
   * @param currentEvent The current event.
   */
  protected onSliderMoved(currentEvent: any) {
    let plot = this.getXDomainPlot();
    if (!plot) {
      return;
    }

    let visibleChannel = this.getVisibleColumnChannel(plot);
    if (!visibleChannel) {
      return;
    }

    // Get the new slider position.
    let scale = plot.column.processed.scale;
    let sourcesVisibility = this.sharedState.sourceLoaderSet.sources.map(v => v.isVisible);
    let visibleMinimum = plot.column.processed.getVisibleMinimum(sourcesVisibility);
    let visibleMaximum = plot.column.processed.getVisibleMaximum(sourcesVisibility);
    let range = visibleMaximum - visibleMinimum;
    let positionMinimum = scale(visibleMinimum);
    let value = scale.invert(positionMinimum + currentEvent.position);

    let rCoordinate = (value - visibleMinimum) / range;
    let loaderMetadata = ExplorationChannelMetadata.fromProcessedMultiPlotChannel(visibleChannel);
    if (loaderMetadata) {
      // If we have metadata, map the r-coordinate from the normalized data value back to the normalized index.
      // This is required as the data is not always linearly spaced.
      rCoordinate = loaderMetadata.explorationSubSweep.mapFromDataToIndexNormalizedValue(rCoordinate);
    }

    // Update the shared state.
    const dimensions = this.sharedState.definedDimensions;
    dimensions.rCoordinatesSet(this.dimensionIndex, rCoordinate);
  }

  /**
   * Get the plot which contains the X domain we're interested in.
   * @returns The plot, or undefined if no plot contains the X domain.
   */
  private getXDomainPlot(): ProcessedPlot | undefined {
    if (!this.layout) {
      return undefined;
    }

    let plots = this.layout.processed.plots.filter(plot => {
      let visibleChannel = this.getVisibleColumnChannel(plot);
      if (!visibleChannel) {
        return false;
      }
      const visibleChannelName = visibleChannel.name;
      return !!this.xDomainNames.find(v => v === visibleChannelName);
    });

    return plots.length ? plots[plots.length - 1] : undefined;
  }

  /**
   * Get the first visible column channel from the plot.
   * @param plot The plot to get the visible column channel from.
   * @returns The first visible column channel, or undefined if no channel is visible.
   */
  private getVisibleColumnChannel(plot: ProcessedPlot): ProcessedMultiPlotChannel | undefined {
    return plot.column.processed.channels.find(v => v.isVisible);
  }
}
